import React from 'react';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { Lframe } from '@latitude/lframe';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import { SITE_URL } from '@/utils/constants';
import { InstalmentInterestFreeCalculator } from '@latitude/instalment-interest-free-calculator';

const ANNUAL_FEE = 65;
const ESTABLISHMENT_FEE = 55;
const MINIMUM_AMOUNT = 50;
const MONTHLY_FEE = 0;
const MONTHLY_TERMS = [12, 18, 24, 36, 40, 48, 50, 60];

const IIFCalculator = ({ location }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title="Interest Free Payment Plan Calculator| Gem by Latitude"
          description="Calculate repayments for Gem Visa purchases on an instalment interest free promotional plan. T&amp;Cs &amp; fees apply."
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <Box backgroundColor={COLOR.GREY6}>
          <div
            css={`
              position: relative;
            `}
          >
            <Lframe
              css={`
                background-image: url(${require('./images/lframe-calculator.png')});
                background-position: 25px 0;
                z-index: auto;

                @media (min-width: ${BREAKPOINT.MD}) {
                  background-position: 54vw 0;
                }

                @media (min-width: ${BREAKPOINT.LG}) {
                  background-size: 1250px auto;
                  background-position: 50vw 0;
                }

                @media (min-width: ${BREAKPOINT.XL}) {
                  background-position: 53vw 0;
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  background-size: 880px auto;
                }
              `}
            />

            <Hero
              title="Gem Visa Instalment Interest Free Calculator"
              text="Calculate repayments for purchases on an instalment interest free promotional plan."
            />
          </div>
        </Box>
        <Box.Section
          backgroundColor={COLOR.GREY6}
          id="calculator"
          css={`
            @media (min-width: ${BREAKPOINT.MD}) {
              margin-top: 48px;
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              margin-top: -96px;
            }
            @media (min-width: ${BREAKPOINT.XL}) {
              margin-top: -40px;
            }
          `}
        >
          <InstalmentInterestFreeCalculator
            heading="Your Instalment Interest Free payment plan"
            monthlyFeeText="Excluding all fees"
            annualFeeText="Total amount paid includes $55 establishment fee and $65 annual account fee (charged $32.50 half-yearly)."
            annualFee={ANNUAL_FEE}
            establishmentFee={ESTABLISHMENT_FEE}
            minimumAmount={MINIMUM_AMOUNT}
            monthlyFee={MONTHLY_FEE}
            monthlyTerms={MONTHLY_TERMS}
            importantInfo={[
              'Credit and lending criteria and fees, including a $55 establishment fee and a $65 annual fee (charged $32.50 half-yearly). Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz.',
              'The estimated repayments quoted are indicative only and are calculated based on the nominated purchase amount and instalment interest free period only. They do not take into account other purchases or balances or any other fees that may apply. Your repayments will be due on a monthly basis and the exact amounts due will be detailed on your monthly statement.',
              'To be eligible for an Instalment Interest Free plan, a minimum finance amount will apply. See current promotions for terms and conditions.',
              'This calculator is current as of 12 June 2024 and may be updated from time to time.',
              'Instalment Interest Free promotions, their terms and minimum payments required are subject to change over time.',
              'Please refer to Gem Visa Conditions of Use for further information about Instalment Plans. Gem Visa is provided by Latitude Financial Services Limited.'
            ]}
          />
        </Box.Section>
      </main>
    </Layout>
  );
};

export default IIFCalculator;
